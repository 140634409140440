import React from "react";
import axios from "axios";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";

function CpUpdate_PAR01() {
  const [data, setData] = React.useState([]);
  const [stk_site, setstk_site] = React.useState("PAR01");
  const modal = document.querySelector("#modal");
  const rmModal = document.querySelector("#rmModal");
  const openModal = document.querySelector("#update_line");
  const closeModal = document.querySelector("#closeModal");
  const rmOk = document.querySelector("#rmOk");
  const rmCloseModal = document.querySelector("#rmCloseModal");

  const [updateNumeroIdu, setUpdateNumeroIdu] = React.useState("");
  const [updateNumeroProdf, setUpdateNumeroProdf] = React.useState("");
  const [update_item_lot, setUpdateItmLot] = React.useState("");

  React.useEffect(() => {
    getIdu_as_trac_bal();
  }, []);

  function getIdu_as_trac_bal() {
    const loadingModal = document.querySelector("#loadingModal");
    loadingModal.showModal();
    axios
      .get(
        `https://inventaire-annuel-api.protecop.net/api_v1/qte_cp_by_site/` +
          stk_site +
          ``
      )
      .then((res) => {
        const data = res.data;
        setData(data);
        loadingModal.close();
      })
      .catch((error) => {
        console.log(error);
        loadingModal.close();
      });
  }

  function updateIdu_as_final(id, itm_loc, qte_cp, itm_lot) {
    setUpdateNumeroIdu(itm_loc);
    setUpdateNumeroProdf(qte_cp);
    setUpdateItmLot(itm_lot);

    console.log("id = " + id);
    modal.showModal();
    closeModal.addEventListener("click", () => modal.close());

    openModal.addEventListener("click", () => {
      const itm_loc = document.querySelector("#update_item_loc").value;
      const qte_cp = document.querySelector("#update_qte_cp").value;
      const itm_lot = document.querySelector("#update_item_lot").value;
      const data_update = {
        itm_lot: itm_lot,
        itm_loc: itm_loc,
        qte_cp: qte_cp,
      };

      axios
        .put(
          `https://inventaire-annuel-api.protecop.net/api_v1/update_article_cp/` +
            id +
            `/`,
          data_update
        )
        .then((res) => {
          const newData = data.filter((data) => data.id != id);
          setData(newData);
          //getIdu_as_trac_bal();
          modal.close();
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  function removeIdu_as_final(id) {
    rmModal.showModal();
    rmCloseModal.addEventListener("click", () => rmModal.close());

    rmOk.addEventListener("click", () => {
      console.log("id = " + id);
      axios
        .delete(
          `https://inventaire-annuel-api.protecop.net/api_v1/delete_article_cp/` +
            id +
            `/`
        )
        .then((res) => {
          const newData = data.filter((data) => data.id != id);
          setData(newData);
          rmModal.close();
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);

    return (
      <span>
        Recherche:{" "}
        <input
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} resultats...`}
          style={{
            fontSize: "1.1rem",
            border: "0",
          }}
        />
      </span>
    );
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Recherche ${count} resultats...`}
      />
    );
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Emplacement",
        accessor: "itm_loc",
      },
      {
        Header: "Reference article",
        accessor: "itm_ref",
      },
      {
        Header: "Cle de recherche",
        accessor: "search_key",
      },
      {
        Header: "Lot",
        accessor: "itm_lot",
      },
      {
        Header: "Quantite comptee",
        accessor: "qte_cp",
      },
      {
        Header: "Options",
        accessor: "options",
      },
    ],
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <div>
      <dialog id="loadingModal" class="dialog">
        <p>Chargement en cours</p>
      </dialog>

      <dialog id="modal" class="dialog">
        <p>Mise à jour</p>

        <label for="update_item_loc">Emplacement</label>
        <input
          type="text"
          name="update_item_loc"
          id="update_item_loc"
          value={updateNumeroIdu}
          onChange={(e) => setUpdateNumeroIdu(e.target.value)}
          class="input-update"
        />

        <label for="update_item_lot">Numéro de lot</label>
        <input
          type="text"
          name="update_item_lot"
          id="update_item_lot"
          value={update_item_lot}
          onChange={(e) => setUpdateItmLot(e.target.value)}
          class="input-update"
        />

        <label for="update_qte_cp">Quantité comptée</label>
        <input
          type="text"
          name="update_qte_cp"
          id="update_qte_cp"
          value={updateNumeroProdf}
          onChange={(e) => setUpdateNumeroProdf(e.target.value)}
          class="input-update"
        />

        <button id="closeModal" class="dialog-close-btn">
          Fermer la fenêtre
        </button>
        <button id="update_line">Mettre à jour</button>
      </dialog>

      <dialog id="rmModal" class="dialog">
        <p>Suppréssion</p>
        <button id="rmCloseModal" class="dialog-close-btn">
          Fermer la fenêtre
        </button>
        <button id="rmOk">Valider la suppréssion</button>
      </dialog>
      <h1>Modification du comptage (PAR01)</h1>
      <input type="submit" onClick={getIdu_as_trac_bal} value={"Actualiser"} />
      <table {...getTableProps()} style={{ border: "solid 1px black" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    borderBottom: "solid 3px red",
                    color: "black",
                  }}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""}
                  </span>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
          <tr>
            <th
              colSpan={visibleColumns.length}
              style={{
                textAlign: "left",
              }}
            >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: "10px",
                        border: "solid 1px gray",
                      }}
                    >
                      {cell.render("Cell")}
                      {console.log(cell.row.original.id)}
                      {cell.column.id == "options" ? (
                        <>
                          <input
                            type="submit"
                            value="Mettre à jour"
                            onClick={() =>
                              updateIdu_as_final(
                                cell.row.original.id,
                                cell.row.original.itm_loc,
                                cell.row.original.qte_cp,
                                cell.row.original.itm_lot
                              )
                            }
                          />
                          <input
                            type="submit"
                            value="Supprimer"
                            onClick={() =>
                              removeIdu_as_final(cell.row.original.id)
                            }
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default CpUpdate_PAR01;
